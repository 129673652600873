.icon {
  background-position: center center;
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
}

.icon-logo_small {
  background: url('../../images/logo_small.png');
  height: 28px;
  width: 42px;
}

.icon-acceleration {
  background: url('../../images/acceleration.svg');
  height: 34px;
  width: 34px;
  background-size: 34px;
}

.icon-magnitude {
  background: url('../../images/magnitude.svg');
  height: 28px;
  width: 28px;
  background-size: 28px;
}

.icon-arrow_right {
  background: url('../../images/arrow_right.png');
  height: 13px;
  width: 8px;
}

.icon_back_w {
  background: url('../../images/icon_back_white.svg');
  height: 20px;
  width: 20px;
  background-size: 20px;
}

.icon_category {
  background: url('../../images/category.svg');
  height: 15px;
  width: 16px;
  background-size: 16px;
}

.icon_arrow-down {
  background: url('../../images/arrow_down.svg');
  height: 8px;
  width: 16px;
  background-size: 16px;
}

.icon_sort {
  background: url('../../images/sort-icon.png');
  height: 17px;
  width: 17px;
}

.icon_clock {
  background: url('../../images/clock.svg');
  height: 15px;
  width: 15px;
  background-size: 15px;
}

.icon_search {
  background: url('../../images/search-icon.svg');
  height: 23px;
  width: 23px;
  background-size: 23px;

  &_small {
    background: url('../../images/search_small.svg');
    height: 16px;
    width: 16px;
    background-size: 16px;
  }
}

.icon_share {
  background: url('../../images/share.png');
  height: 18px;
  width: 18px;
}

.icon_calendar {
  background: url('../../images/calendar.png');
  width: 23px;
  height: 18px;
}

.icon_email {
  background-image: url('../../images/envelop.png');
  width: 14px;
  height: 18px;
}

.icon_level {
  background-image: url('../../images/level.png');
  width: 16px;
  height: 18px;
}

.icon_close {
  background: url('../../images/close.svg') center no-repeat;
  width: 16px;
  height: 10px;
}