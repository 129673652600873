$dropdown: (
  height: 35px,
  width: 200px,
  max-width: 220px,
  padding: 0 10px,
  line-height: 33px,
  arrow-top: 5px,
  arrow-right: 11px,
  list-top: 55px,
  list-pad-side: 18px,
);

@mixin dropdown() {
  @include border($color: color($grey, dark-5));
  color: $black;
  background-color: $white;
  text-transform: uppercase;
  width: setting($dropdown, width);
  height: setting($dropdown, height);
  line-height: setting($dropdown, line-height);
}

@mixin dropdown-shadow() {
  -webkit-box-shadow: 0 6px 25px 3px rgba(0,0,0,0.15);
  -moz-box-shadow: 0 6px 25px 3px rgba(0,0,0,0.15);
  box-shadow: 0 6px 25px 3px rgba(0,0,0,0.15);
}

@mixin dropdown-spacer() {
  @include border-left($color: color($grey, dark-5));
  @include border-right($color: color($grey, dark-5));
  position: absolute;
  content: '';
  width: setting($dropdown, width);
  height: 23px;
  background: $white;
  top: 33px;
  left: -1px;
  z-index: 99999;
}
