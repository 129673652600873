.Btn {
  @include font-regular;
  @include border;
  background: transparent;
  outline: 0;
  text-transform: uppercase;
  border-radius: 2px;
  color: $black;
  padding: 6px 14px;
  line-height: 1;

  &:focus {
    outline: 0;
  }

  &_primary {
    color: $white;
    background: color($purple, light);
    border-color: color($purple, light);

    /* &:hover {
      color: $black;
      background: transparent;
      border-color: color($grey, light-2);
    } */
  }

  &__purple {
    color: $white;
    font-size: 16px;
    @include font-bold;
    line-height: 1;
    border: none;
    background: color($purple, light-2);
    outline: 0;
    text-transform: uppercase;
    border-radius: 0;
    padding: 12px 20px;

    &:hover,
    &:focus {
      color: $white;
      background: color($purple, light-3);
    }

    &:focus {
      outline: 0;
    }

    &.disabled {
      background-color: #e0cdf2;
    }
  }

  &__purple-link {
    color: color($purple, light-2);
    font-size: 12px;
    @include font-regular;
    line-height: 1;
    border: none;
    background: transparent;
    outline: 0;
    text-transform: uppercase;
    border-radius: 0;
    padding: 14px 20px;

    &:hover,
    &:focus {
      color: color($purple, light-3);
    }

    &:focus {
      outline: 0;
    }
  }
}

.show-more-btn {
  @include border($color: color($grey, light-6));
  @include font-medium;
  display: inline-block;
  font-size: setting($font-size, m);
  color: color($purple, light-2);
  text-align: center;
  background: $white;
  text-transform: uppercase;
  padding: 9px 28px;

  &:hover {
    color: color($purple, light-2);
  }
}
