.Categories {
  position: relative;
  display: inline-block;

  &__header {
    @include font-medium;
    text-transform: uppercase;
    color: color($grey, darkest-2);
    margin: 0 0 25px;
    font-size: setting($font-size, m);
  }

  &__filter {
    position: relative;
    z-index: 999;

    &_focused {
      @include dropdown-shadow;
      height: 50px;

      .Categories__input-arrow-wrapper {
        top: 9px;
      }

      .Categories__input {
        text-transform: none;
      }
    }

  }

  &__list {
    @include dropdown-shadow;
    @include border($color: color($grey, dark-5));
    background-color: $white;
    position: absolute;
    top: calc(#{setting($dropdown, list-top)} + 1px);
    z-index: 9999;
    overflow: hidden;
    padding: 25px setting($dropdown, list-pad-side);

    &_full-width {
      height: 712px;
      width: setting($container, content-width);
    }
  }

  &__column {
    width: setting($categories, column-width);
    max-height: 560px;
    overflow-y: hidden;
    overflow-x: hidden;
    float: left;
    margin-right: 10px;
    padding-top: 13px;

    &-wrapper {
      display: inline-block;
    }

    &_search {
      border-top: 0;
      padding-top: 0;
      overflow-y: hidden;
    }

    .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
      right: -4px;
    }
  }

  &__item {
    @include border-bottom($color: color($grey, lightest-5));
    @include ellipsis;
    font-size: setting($font-size);
    color: color($grey, darkest-2);
    width: 100%;
    height: setting($categories, item-width);
    line-height: setting($categories, item-width);
    vertical-align: middle;
    padding-left: setting($categories, item-pad-left);
    padding-right: setting($categories, item-pad-right);
    position: relative;

    &-wrapper {
      width: setting($categories, column-width);

      .icon {
        position: absolute;
        right: setting($categories, item-pad-right);
        top: 17px;
      }
    }

    &_selected {
      border-bottom-color: color($grey, darker-2);
    }


    &:hover,
    &:active {
      color: color($grey, darkest-2);
    }

    &_active {
      color: color($purple, light-2);
      border-bottom-color: color($purple, light-2);

      &:hover,
      &:active {
        color: color($purple, light-2);
      }
    }
  }

  &__input {
    @include categories-input;
    cursor: pointer;

    &-arrow-wrapper {
      position: absolute;
      right: setting($dropdown, arrow-right);
      top: setting($dropdown, arrow-top);
    }

    &-wrapper {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      line-height: 1.6;
    }
  }

  .fa-chevron-down {
    font-size: 9px;
  }

  &__overlay {
    background-color: color($pink, lightest);
    opacity: .5;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
  }

  &_active {
    &::before {
      @include dropdown-spacer;
      top: 34px;
      left: 0;
    }
  }
}
