.Navbar {
  &_styled {
    background: color($purple, darker);
    border: 0;

    &.navbar {
      .Navbar__navbar-brand {
        margin-left: -73px;
        margin-top: 9px;
      }
    }

    &.navbar-default {
      .navbar-nav {
        > .open {
          > a {
            background: color($purple, darker);

            &:hover {
              background: color($purple, darker);
            }
          }
        }

        > li > a {
          color: color($grey, dark-4);

          &:hover,
          &:focus {
            color: color($grey, dark-4);
          }
        }
      }
    }
  }

  &__dropdown-toggle {
    margin-right: 24px;
    margin-top: 5px;
  }

  &__user-icon {
    width: 30px;
    height: 30px;
    display: inline-block;
    background: $white;
    border-radius: 50%;
    float: right;
  }

  &__user-name {
    vertical-align: middle;
    margin-right: 10px;
  }
}
