.app-jumbotron {
  position: relative;
  background: color($purple, darker);
  margin-bottom: 52px;
  z-index: 999;
  min-height: 126px;

  * {
    color: $white;
  }

  .page-description {
    @include font-regular;
    font-size: setting($font-size, m);
    margin-bottom: 0;
  }

  &__header {
    display: inline-block;
    line-height: 1;
    font-size: 32px;
    margin-bottom: 13px;
    text-transform: uppercase;

    &:hover,
    &:focus {
      color: $white;
    }
  }

  &__back-btn {
    .icon {
      float: left;
    }
  }
}
