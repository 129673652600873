body {
  &#auth-layout {
    background-color: $white;
    .Auth {
      font-family: Avenir-Light;
      font-size: 16px;
      letter-spacing: 0;
      .auth-container {
        margin: 100px auto 0;
        width: 381px;
        .Logo {
          height: 41px;
        }
        .login-form {
          margin: 0 40px;
          position: relative;
          .loginError {
            position: absolute;
            top: 50px;
            height: 32px;
            svg {
              float: left;
            }
            .loginErrorText {
              margin-left: 11px;
              line-height: 32px;
              color: $error;
            }
          }
          .authInputs {
            position: absolute;
            top: 126px;
            .form-group {
              margin-bottom: 26px;
              label {
                display: block;
                font-family: Avenir-Light;
                font-size: 14px;
                color: #999999;
                font-weight: 100;
                margin-bottom: 9px;
              }
              input {
                border-top: none;
                border-left: none;
                border-right: none;
                background-color: $white !important;
                border-bottom: 1px solid #979797;
                width: 302px;
                height: 36px;
                padding-top: 1px;
                padding-left: 1px;
                font-size: 16px;
                &:-webkit-autofill {
                  background-color: $white !important;
                  -webkit-box-shadow: 0 0 0px 1000px white inset;
                }
                &:focus {
                  border-top: none;
                  border-left: none;
                  border-right: none;
                  border-bottom: 1px solid #979797;
                  outline: 0px !important;
                  -webkit-appearance: none;
                }
                &:hover {
                  border-top: 1px solid #8C60B3;
                  border-left: 1px solid #8C60B3;
                  border-right: 1px solid #8C60B3;
                  border-bottom: 1px solid #8C60B3;
                  padding-top: 0;
                  padding-left: 0;
                  outline: 0px !important;
                  -webkit-appearance: none;
                }
              }
              .LoginBtn {
                font-family: Avenir-Medium;
                font-size: 16px;
                color: $white;
                background-color: #5F3897;
                letter-spacing: 0;
                width: 145px;
                height: 45px;
                margin-top: 49px;
                &:disabled {
                  background-color: $black;
                }
              }
            }
          }
          .loginRemember {
            label {
              font-family: Avenir-Light;
              font-size: 12px;
              font-weight: 100;
            }
          }
        }
      }
    }
    .footer {
      &.auth-footer {
        position: absolute;
        bottom: 46px;
        width: 100%;
        height: 40px;
        background-color: $white;
        .container {
          .authCompany {
            font-family: Avenir-Light;
            font-size: 10px;
            line-height: 20px;
          }
          .authCopy {
            font-family: Avenir-Light;
            font-size: 10px;
            line-height: 20px;
          }
        }
      }
    }
  }
}