.fade-enter-active {
  transition: opacity .4s;
}

.fade-leave-active {
  display: none;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.Fade-enter-active,
.Fade-leave-active {
  transition: opacity .3s;
}

.Fade-enter,
.Fade-leave-active {
  opacity: 0;
}
