.Blacklist {
  &__container {
    background: $white;
    padding: setting($app, side-padding);
  }

  &__top {
    margin-bottom: 36px;
  }

  &__tags {
    margin-bottom: 36px;
  }

  &__custom-tag {
    position: relative;
    width: 247px;
    margin-bottom: 36px;
  }

  &__add-tag {
    @include border($color: $black);
    color: $black;
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 7px;

    span {
      font-size: 19px;
      position: relative;
      top: -3px;
    }
  }

  &__input {
    @include border-bottom($color: $black);
    @include font-regular;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    outline: 0;
    padding: 8px 25px 8px 0;
    color: $black;
    width: 100%;
  }
}
