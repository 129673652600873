.Dropdown {
  @include dropdown;
  display: inline-block;
  vertical-align: top;
  max-width: setting($dropdown, width);
  position: relative;

  &__input {
    opacity: 0;
    position: absolute;
    border: 0;
    border-radius: (setting($dropdown, height) / 2);
    width: 175px;
    outline: 0;
    padding: setting($dropdown, padding);
    z-index: -1;

    &_visible {
      opacity: 1;
      position: relative;
      z-index: 1;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    text-transform: none;
    padding: 0 setting($dropdown, list-pad-side) 15px;

    .Dropdown__item {
      cursor: pointer;

      a {
        @include ellipsis();
        @include border-bottom($color: color($grey, lightest-5));
        padding-left: setting($categories, item-pad-left);
        padding-right: setting($categories, item-pad-right);
        color: color($grey, darkest-2);
        height: setting($categories, item-width);
        line-height: setting($categories, item-width);
        display: block;

        &.Dropdown__item_active {
          color: color($purple, light-2);
          border-bottom-color: color($purple, light-2);

          &:hover,
          &:active {
            color: color($purple, light-2);
          }
        }
      }

    }

    &__container {
      // max-height: 223px;
      // overflow: auto;
    }

    &__wrapper {
      @include border($color: color($grey, dark-5));
      min-width: setting($dropdown, width);
      overflow: auto;
      padding: 0;
      width: setting($categories, column-width);
      z-index: 9999;
      background-color: $white;
      position: absolute;
      margin: 0;

      &_open {
        top: setting($dropdown, list-top);
        left: -1px;
      }

      &_right {
        left: auto;
        right: 0;
      }

      &_is-input {
        padding-top: setting($dropdown, height);
      }
    }
  }

  &__selected-item {
    @include ellipsis();
    max-width: setting($dropdown, width);
    width: setting($dropdown, width);
    cursor: pointer;
    padding: setting($categories, input-pad);
  }

  .fa-chevron-down {
    font-size: 9px;
    position: absolute;
    right: 9px;
    top: 7px;
  }

  &__arrow-wrapper {
    position: absolute;
    right: setting($dropdown, arrow-right);
    top: setting($dropdown, arrow-top);
    line-height: 1.6;
  }

  &_active {
    &::before {
      @include dropdown-spacer
    }
  }
}
