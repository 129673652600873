$categories: (
  column-width: 250px,
  item-width: 47px,
  item-pad-right: 17px,
  item-pad-left: 12px,
  input-pad: 0 30px 0 18px,
);

@mixin categories-input() {
  @include dropdown;
  outline: 0;
  padding: setting($categories, input-pad);
  line-height: setting($dropdown, height);
}
