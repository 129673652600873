.Mentions {
  &__filters {
    margin-bottom: 30px;
  }

  &__overview {
    background-color: $white;
    max-height: 360px;

    &__number {
      @include font-regular;
      color: $black;
      font-size: setting($font-size, xm-2);
      line-height: 32px;
      margin-top: 7px;
      display: block;
    }

    &__label {
      font-size: setting($font-size, sm);
      text-transform: uppercase;
      color: color($grey, dark-3);
      display: block;
    }

    &__numbers {
      padding: 20px 0 0;
    }

    &__chart {
      height: setting($mentions, chart-height);
      padding: 0 40px 0 26px;
      position: relative;

      .ct-series-a {

        .ct-point-circle {
          // display: none;
          opacity: 0;
          transition: opacity .4s;
        }

        .ct-line {
          stroke-width: 3px;
        }

      }

      .ct-labels {
        .ct-label {
          @include font-light;
          font-size: setting($font-size, sm-2);
          color: color($grey, lighter-4);
        }
      }


      .ct-chart {
        height: setting($mentions, chart-height);
      }

      &__labels {
        display: table;
        width: calc(100% - 41px);
        margin-top: 5px;
        float: right;
        padding-right: 10px;

        div {
          @include font-light;
          font-size: setting($font-size, sm);
          display: table-cell;
          width: 33.3%;
          vertical-align: middle;
          text-align: center;
          color: color($grey, lighter-4);

          &:first-child {
            text-align: left;
          }

          &:last-child {
            text-align: right;
          }
        }
      }

      &_hovered {
        .ct-series-a {

          .ct-point-circle {
            // display: block;
            opacity: 1;
          }
        }
      }
    }
  }

  .ct-labels {
    foreignObject {
      &:last-child {
        .ct-label {
          left: -30px;
        }
      }
    }
  }

  .ct-label {
    &.ct-horizontal {
      &.ct-end {
        @extend .text;
        @include font-light;
        color: color($grey, lighter-4);
        display: inline-block;
        height: 40px !important;
        padding-top: 15px;
        position: relative;
        width: 33% !important;
      }
    }
  }

  &__box {
    display: table;
    height: 60px;
    width: 100%;

    &__content {
      @include font-regular;
      font-size: setting($font-size, xm-2);
      display: table-cell;
      vertical-align: middle;
      color: $black;

      &_keyword {
        width: 40%;
      }

      &_acceleration {
        width: 24%;
      }

      &_first {
        box-sizing: content-box;
        padding-left: setting($mentions, padding-side);
        padding-right: 25px;
        width: 28px;
      }
    }

    &__icon {
      @include font-light;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: color($purple, lighter);
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      color: $white;
      font-size: setting($font-size, l);
    }
  }

  &__list {
    background-color: $white;

    &__container {
      @include border-bottom($color: color($grey, lightest-3));
      padding-bottom: 37px;
      padding-top: 34px;
    }

    &__item {
      background-color: $white;
      padding-left: setting($mentions, padding-side);
      padding-right: setting($mentions, padding-side);
      width: 100%;

      &__show-more {
        color: color($purple, light-2);
        font-size: setting($font-size);
        position: relative;
        top: -4px;
        margin-left: 20px;

        &:hover {
          color: color($purple, light-2);
        }
      }
    }

    &__part {
      color: color($grey, darkest-6);

      &_text {
        width: 98%;
        margin-bottom: 15px;
        color: color($grey, darkest-6);
      }

      &_metrics {
        float: left;
        margin-right: 67px;
      }

      .box-index {
        margin-right: 11px;
      }

    }

    &__title {
      @include ellipsis(setting($mentions, text-width));
      color: color($grey, darkest-4);
      font-size: setting($font-size);
      line-height: 1.2;
      margin-bottom: 0;
    }

    &__text {
      color: color($grey, darkest-6) !important;

      &_dotted {
        @include ellipsis(79%);
      }

      &_highlighted {
        @include font-medium;
        line-height: 1;
        color: color($purple, light-2);
      }
    }

    &__header {
      text-transform: uppercase;
      padding: 0 setting($mentions, padding-side);
      margin-bottom: 18px;
      margin-top: 25px;
      color: color($grey, darkest-6);
    }
  }

  &__show-more {
    background: $white;
    padding: 23px 0 53px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1;
  padding: 5px;
  opacity: 1;
  border-radius: 3px;
  text-align: center;
  pointer-events: none;
  color: $white;
  transition: opacity .1s ease-out;

  &.tooltip-hidden {
    opacity: 0;
  }
}

.overview-bar {
  background-color: color($grey, lightest);
  height: 5px;
  position: relative;

  &__separator {
    background-color: color($grey, lightest);
    display: block;
    height: 13px;
    left: calc(50% - 1px);
    position: absolute;
    top: -4px;
    width: 2px;
  }
}
