.Profile {
  &__container {
    background: $white;
    padding: 35px 40px 62px;
    min-height: 608px;
  }

  &__form {
    position: relative;
    width: 250px;
    margin-bottom: 62px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h4 {
    margin-top: 5px;
    margin-bottom: 34px;
  }

  &__input {
    @include font-regular;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid color($grey, dark-5);
    outline: 0;
    padding: 8px 5px;
    margin-bottom: 31px;
    color: $black;
    width: 100%;
  }

  &__header {
    margin-bottom: 13px;

    h4 {
      margin-bottom: 52px;
    }
  }

  .Columns__wrapper {
    width: 894px;
  }
}
