$finder-height:         35px;
$finder-width:          200px;
$finder-border:         1px;
$finder-spacer:         20px;

.Finder {
  background:           white;
  display:              inline-block;
  position:             relative;
  z-index:              1000;

  &, & * {
    box-sizing:         border-box;
  }

  @include              border($color: color($grey, dark-5), $width: $finder-border);
  width:                $finder-width;
  height:               $finder-height;


  &__selection {
    height:             $finder-height;
    line-height:        $finder-height;
    text-transform:     uppercase;
  }

  &__dropdown {
    display:            none;
    position:           absolute;
  }

  &.active {
    @include            dropdown-shadow;
    border-bottom:      none;
    z-index:            1001;

    .Finder__dropdown {
      position:         relative;
      @include          border($color: color($grey, dark-5), $width: $finder-border);
      @include          dropdown-shadow;
      display:          inline-block;
      min-width:        $finder-width - 2*$finder-border;
      margin-left:      -$finder-border;
      margin-top:       $finder-spacer - 2*$finder-border;
      background:       white;

      &:before {
        @include        border($color: color($grey, dark-5), $width: $finder-border);
        border-top:       none;
        border-bottom:    none;

        content:        '';
        display:        block;
        width:          $finder-width;
        height:         $finder-spacer;
        margin-left:    -$finder-border;
        margin-top:     -$finder-spacer;
        background:     white;
      }
    }
  }

  &__overlay {
    content:          "";
    display:          none;
    background:       color($pink, lightest);
    opacity:          .5;
    width:            100%;
    height:           100%;
    position:         fixed;
    top:              0;
    left:             0;
    z-index:          99;
  }

  &.active+&__overlay {
    display:          block;
  }
}

.Columns {
  &__wrapper {
    width: 972px;
    margin: 18px;
    overflow-y: auto;
  }

  &__buttons {
    position: absolute;
    white-space: nowrap;
    top: 30px;
    right: 18px;
  }

  &__container {
    display: flex;
  }

  &__column+&__column {
    margin-left: 10px;
  }

  &__column {
    flex-grow: 0; flex-shrink: 0;
    width: 220px;
    padding: 0;
    margin: 0 0 38px;

    li {
      position: relative;
      color: color($grey, light-6);
      font-size: 15px;
      @include font-regular;
      list-style: none;
      height: 39px;
      line-height: 40px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      padding: 0 16px 0 4px;
      border-bottom: 1px solid color($grey, light-5);

      &.folder {
        &:after {
          content: ' ';
          position: absolute;
          width: 8px;
          height: 40px;
          top: 0;
          right: 4px;
          background: url('/images/arrow_right.png') center center no-repeat;
        }
      }

      &.active {
        color: $black;
        font-weight: 500;
        border-bottom: 1px solid black;
      }

      &.selected {
        color: $black;
        font-weight: 500;
        border-bottom: 1px solid $black;
      }
    }
  }
}

.CategorySelection {
  display: flex;
  cursor: pointer;

  &__label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 5px 0 18px;
    flex-grow: 1; flex-shrink: 1;
  }

  &__icon {
    width: 30px;
    flex-grow: 0; flex-shrink: 0;
  }
}