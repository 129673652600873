.Breadcrumbs {
  clear: both;
  float: left;
  list-style: none;
  margin-bottom: 13px;
  padding: 0;
  width: 100%;

  &__item {
    @include font-light;
    color: color($grey, darker);
    float: left;
    font-size: setting($font-size, xl);
    line-height: 1;
    text-transform: uppercase;

    &::after {
      content: '\f101';
      font-family: 'FontAwesome';
      font-size: 28px;
      padding: 0 10px;
      position: relative;
      top: -3px;
    }

    &:last-child {
      color: color($purple);
      padding-right: 0;
    }

    &:last-child::after {
      content: '';
      padding: 0;
    }
  }

  &__link {
    color: color($grey, darker);

    &:hover {
      color: color($grey, darker);
    }
  }
}
