.content-container {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  -webkit-flex: 1 0 auto;
  flex: 1 0 auto;
}

.Navbar,
.Footer-container {
  -ms-flex-negative: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}

#vue-root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100vh;
}

.container {
  &__app {
    padding: 10px setting($app, side-padding) 10px 0;
  }

  @media (min-width: 1200px) {
    width: setting($container, desktop-width);
  }
}
