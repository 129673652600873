.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(44, 36, 54, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    width: 600px;
    margin: 0 auto;
    padding: 68px;
    background: #fff  url('/images/modal-ok.png') 450px 68px no-repeat;
    transition: all .3s ease;
}

.modal-header {
    padding: 0 0 17px;
    border-bottom: none;

    h4 {
        margin: 0;
    }
}

.modal-body {
    padding: 0;
}

.modal-footer {
    text-align: left;
    padding: 57px 0 12px;
    border-top: none;
}

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}