.Chip {
  @include border($color: $black);
  @include font-regular;
  display: inline-block;
  border-radius: 20px;
  padding: 7px 35px 8px 15px;
  position: relative;
  font-size: setting($font-size, sm-3);
  margin: 0 12px 12px 0;

  &__close {
    position: absolute;
    font-size: setting($font-size, xm);
    color: $black;
    top: 1px;
    right: 12px;
  }
}
