.Footer {
  @include font-regular;
  padding: 50px 17px;
  position: relative;
  z-index: 999;

  * {
    color: color($grey, light-6);
  }

  &__list {
    list-style: none;
    padding: 0;

    li {
      display: inline-block;
      font-size: 15px;
    }
  }

  &__left {
    float: left;
    padding-left: 9px;
  }

  &__right {
    float: right;
    padding-right: 9px;
    text-align: right;
  }

  &__separator {
    padding: 0 15px;
  }
}
