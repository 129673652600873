$font-size: (
  sm-4: 11px,
  sm-3: 13px,
  sm-2: 10px,
  sm: 12px,
  default: 14px,
  m: 16px,
  xm: 18px,
  xm-2: 20px,
  l: 30px,
  l-2: 28px,
  xl: 36px,
  xxl: 52px,
);

@mixin font-regular {
  @include dinpro-regular;
}

@mixin font-bold {
  @include dinpro-bold;
}

@mixin font-light {
  @include dinpro-light;
}

@mixin font-medium {
  @include dinpro-medium;
}

@mixin font-black {
  @include dinpro-black;
}

// DINPro
@mixin dinpro-regular {
  font-family: 'DINPro', sans-serif;
  font-style: normal;
  font-weight: 400;
}

@mixin dinpro-bold {
  font-family: 'DINPro', sans-serif;
  font-style: normal;
  font-weight: bold;
}

@mixin dinpro-light {
  font-family: 'DINPro', sans-serif;
  font-style: normal;
  font-weight: 300;
}

@mixin dinpro-medium {
  font-family: 'DINPro', sans-serif;
  font-style: normal;
  font-weight: 500;
}

@mixin dinpro-black {
  font-family: 'DINPro', sans-serif;
  font-style: normal;
  font-weight: 900;
}

// Metronic
@mixin metronic-light {
  font-family: 'Metronic Pro', sans-serif;
  font-style: normal;
  font-weight: 300;
}

@mixin metronic-air {
  font-family: 'MetronicProAir', sans-serif;
  font-style: normal;
  font-weight: 300;
}

@mixin metronic-semi-bold {
  font-family: 'Metronic Pro', sans-serif;
  font-style: normal;
  font-weight: 500;
}

@mixin metronic-regular {
  font-family: 'Metronic Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
}
