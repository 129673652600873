.Tabs {
  list-style: none;
  margin: 0;
  padding: 0;

  &__elem {
    display: inline-block;

    a {
      @include font-regular;
      font-size: setting($font-size, xm);
      color: $black;
      text-transform: uppercase;
      padding: 14px 26px;
      display: inline-block;
    }

    &_active {
      background-color: $white;
    }
  }
}
