.Trends {
  &__item {
    @include border-bottom($color: color($grey, lighter-6));
    @include font-regular;
    font-size: setting($font-size, xm-2);
    background-color: $white;
    display: table;
    max-height: 70px;
    width: 100%;
    cursor: pointer;

    &__container {
      display: table-cell;
      float: none;
      vertical-align: middle;

      &_first {
        padding-left: 30px;
      }

      &_last {
        padding-right: 23px;
        text-align: right;
      }
    }

    &_header {
      @include font-medium;
      font-size: setting($font-size, sm-4);
      text-transform: uppercase;
      background: color($grey, lighter-6);
      padding: 10px 0;
      border: 0;
      cursor: default;
    }
  }

  &__index {
    margin-right: 10px;
    width: 37px; display: inline-block;
    position: relative;
  }

  &__chart {
    &-container {
      position: relative;
      padding-right: 23px;
    }

    &__shadow {
      -webkit-box-shadow: inset 47px 0 25px -25px $white;
         -moz-box-shadow: inset 47px 0 25px -25px $white;
              box-shadow: inset 47px 0 25px -25px $white;
      display: block;
      height: calc(100% - 1px);
      left: 16px;
      position: absolute;
      top: 0;
      width: 30px;
      z-index: 9;
    }
  }

  &__chart {
    .ct-chart {
      width: 189px;
      float: right;
      max-height: 70px;
      overflow: hidden;
    }
  }

  &__filter {
    &__item {
      float: left;
      margin-right: 20px;
    }

    &__header {
      text-transform: uppercase;
    }

    &__top {
      max-height: 20px;
      margin-bottom: 15px;
    }
  }

  &__search {
    float: right;
    position: relative;

    &__input {
      @include categories-input;

      &-container {
        position: relative;

        .icon {
          position: absolute;
          top: 9px;
          right: 9px;
        }
      }

      &_focused {
        text-transform: none;
      }
    }

    &__icon {
      position: absolute;
      right: 23px;
      top: 40px;
    }
  }

  &__loader-container {
    margin-bottom: 61px;
  }
}

.navbar.navbar-default.navbar-static-top {
  margin-bottom: 0;
}

.dumped {
  box-sizing: border-box;
  padding-left: 60px;
  padding-right: 60px;
}

.filter-bar {
  clear: both;
  float: left;
  list-style: none;
  margin: 0;
  margin-bottom: 15px;
  padding: 0;
  width: 100%;

  .filter {
    float: left;
    margin-right: 20px;
  }
}

.filter {
  li {
    .btn-group {
      margin: 0 24px 0 12px;

      .btn.btn-xs {
        border-radius: 20px;
        font-size: 12px;
        line-height: 2;
        padding: 1px 10px;
      }
    }
  }
}


/* Apply different styling per serie */
.ct-point-circle {
  stroke-width: 0;
  fill: color($purple, light-3);
}

.ct-series-a {
  .ct-point-circle {
    stroke: color($purple, light-3);
  }

  .ct-area {
    fill: rgb(203, 200, 200);
  }

  .ct-line {
    stroke: color($purple, light-2);
    stroke-width: 2px;
  }
}

.subdown-menu {
  list-style: none;
  padding-left: 15px;

  /* li > a {
    clear: both;
    color: color($grey, darkest-2);
    display: block;
    font-weight: normal;
    line-height: 1.428571429;
    padding: 3px 20px;
    white-space: nowrap;

    &:hover {
      background-color: color($grey, lightest-2);
      color: color($grey, darkest-3);
      text-decoration: none;
    }
  } */
}
