@font-face {
  font-family: 'MetronicProAir';
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/MetronicPro/MetronicProAir.eot');
  src: url('../../fonts/MetronicPro/MetronicProAir.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/MetronicPro/MetronicProAir.woff2') format('woff2'),
  url('../../fonts/MetronicPro/MetronicProAir.woff') format('woff'),
  url('../../fonts/MetronicPro/MetronicProAir.ttf') format('truetype'),
  url('../../fonts/MetronicPro/MetronicProAir.svg#MetronicProAir') format('svg');
}

@font-face {
  font-family: 'Metronic Pro';
  font-style: italic;
  font-weight: 300;
  src: url('../../fonts/MetronicPro/MetronicProLight-Italic.eot');
  src: url('../../fonts/MetronicPro/MetronicProLight-Italic.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/MetronicPro/MetronicProLight-Italic.woff2') format('woff2'),
  url('../../fonts/MetronicPro/MetronicProLight-Italic.woff') format('woff'),
  url('../../fonts/MetronicPro/MetronicProLight-Italic.ttf') format('truetype'),
  url('../../fonts/MetronicPro/MetronicProLight-Italic.svg#MetronicProLight-Italic') format('svg');
}


@font-face {
  font-family: 'Metronic Pro';
  font-weight: 500;
  font-style: normal;
  src: url('../../fonts/MetronicPro/MetronicProSemiBold.eot');
  src: url('../../fonts/MetronicPro/MetronicProSemiBold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/MetronicPro/MetronicProSemiBold.woff2') format('woff2'),
    url('../../fonts/MetronicPro/MetronicProSemiBold.woff') format('woff'),
    url('../../fonts/MetronicPro/MetronicProSemiBold.ttf') format('truetype'),
    url('../../fonts/MetronicPro/MetronicProSemiBold.svg#../../fonts/MetronicPro/MetronicProSemiBold') format('svg');
}

@font-face {
  font-family: 'Metronic Pro';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/MetronicPro/MetronicProBold.eot');
  src: url('../../fonts/MetronicPro/MetronicProBold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/MetronicPro/MetronicProBold.woff2') format('woff2'),
  url('../../fonts/MetronicPro/MetronicProBold.woff') format('woff'),
  url('../../fonts/MetronicPro/MetronicProBold.ttf') format('truetype'),
  url('../../fonts/MetronicPro/MetronicProBold.svg#MetronicProBold') format('svg');
}

@font-face {
  font-family: 'Metronic Pro';
  font-style: italic;
  font-weight: normal;
  src: url('../../fonts/MetronicPro/MetronicProRegular-Italic.eot');
  src: url('../../fonts/MetronicPro/MetronicProRegular-Italic.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/MetronicPro/MetronicProRegular-Italic.woff2') format('woff2'),
  url('../../fonts/MetronicPro/MetronicProRegular-Italic.woff') format('woff'),
  url('../../fonts/MetronicPro/MetronicProRegular-Italic.ttf') format('truetype'),
  url('../../fonts/MetronicPro/MetronicProRegular-Italic.svg#MetronicProRegular-Italic') format('svg');
}

@font-face {
  font-family: 'Metronic Pro';
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/MetronicPro/MetronicProLight.eot');
  src: url('../../fonts/MetronicPro/MetronicProLight.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/MetronicPro/MetronicProLight.woff2') format('woff2'),
  url('../../fonts/MetronicPro/MetronicProLight.woff') format('woff'),
  url('../../fonts/MetronicPro/MetronicProLight.ttf') format('truetype'),
  url('../../fonts/MetronicPro/MetronicProLight.svg#MetronicProLight') format('svg');
}

@font-face {
  font-family: 'Metronic Pro';
  font-style: italic;
  font-weight: 600;
  src: url('../../fonts/MetronicPro/MetronicProBold-Italic.eot');
  src: url('../../fonts/MetronicPro/MetronicProBold-Italic.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/MetronicPro/MetronicProBold-Italic.woff2') format('woff2'),
  url('../../fonts/MetronicPro/MetronicProBold-Italic.woff') format('woff'),
  url('../../fonts/MetronicPro/MetronicProBold-Italic.ttf') format('truetype'),
  url('../../fonts/MetronicPro/MetronicProBold-Italic.svg#MetronicProBold-Italic') format('svg');
}

@font-face {
  font-family: 'Metronic Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/MetronicPro/MetronicProRegular.eot');
  src: url('../../fonts/MetronicPro/MetronicProRegular.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/MetronicPro/MetronicProRegular.woff2') format('woff2'),
  url('../../fonts/MetronicPro/MetronicProRegular.woff') format('woff'),
  url('../../fonts/MetronicPro/MetronicProRegular.ttf') format('truetype'),
  url('../../fonts/MetronicPro/MetronicProRegular.svg#MetronicProRegular') format('svg');
}

@font-face {
  font-family: 'Metronic Pro';
  font-style: normal;
  font-weight: 900;
  src: url('../../fonts/MetronicPro/MetronicProBlack.eot');
  src: url('../../fonts/MetronicPro/MetronicProBlack.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/MetronicPro/MetronicProBlack.woff2') format('woff2'),
  url('../../fonts/MetronicPro/MetronicProBlack.woff') format('woff'),
  url('../../fonts/MetronicPro/MetronicProBlack.ttf') format('truetype'),
  url('../../fonts/MetronicPro/MetronicProBlack.svg#MetronicProBlack') format('svg');
}

@font-face {
  font-family: 'Metronic Pro';
  font-style: italic;
  font-weight: 900;
  src: url('../../fonts/MetronicPro/MetronicProBlack-Italic.eot');
  src: url('../../fonts/MetronicPro/MetronicProBlack-Italic.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/MetronicPro/MetronicProBlack-Italic.woff2') format('woff2'),
  url('../../fonts/MetronicPro/MetronicProBlack-Italic.woff') format('woff'),
  url('../../fonts/MetronicPro/MetronicProBlack-Italic.ttf') format('truetype'),
  url('../../fonts/MetronicPro/MetronicProBlack-Italic.svg#MetronicProBlack-Italic') format('svg');
}
