/* background */
.bgrd {
  &-c {
    &_w {
      background-color: $white;
    }

    &_green {
      background-color: color($green);
    }

    &_pink {
      background-color: color($red);
    }
  }
}

/* no */
.no {
  &-padding {
    padding: 0;
  }

  &-p_t {
    padding-top: 0;
  }

  &-margin {
    margin: 0;
  }

  &-border {
    border: 0;
  }
}

/* text */
.text {
  font-size: setting($font-size);

  &_label {
    color: color($grey, light-3);
  }

  &_large {
    font-size: setting($font-size, l);
  }

  &_sm {
    font-size: setting($font-size, sm);
  }

  &_sm-2 {
    font-size: setting($font-size, sm-2);
  }

  &_sm-3 {
    font-size: setting($font-size, sm-3);
  }

  &_l-2 {
    font-size: setting($font-size, l-2);
  }

  &_m {
    font-size: setting($font-size, m);
  }

  &_xm {
    font-size: setting($font-size, xm);
  }

  &_xl {
    font-size: setting($font-size, xl);
  }

  &_bold {
    font-weight: bold;
  }

  &_semi-bold {
    font-weight: 500;
  }

  &_light {
    @include font-light;
  }

  &_dark {
    color: color($grey, dark);
  }

  &_darkest-5 {
    color: color($grey, darkest-5);
  }

  &_darkest-2 {
    color: color($grey, darkest-2);
  }

  &_black {
    color: $black;
  }

  &_light-3 {
    color: color($grey, light-3);
  }

  &_lighter-4 {
    color: color($grey, lighter-4);
  }

  &_purple-lighter {
    color: color($purple, lighter);
  }

  &_air {
    @include font-light;
  }

  &_reg {
    @include font-regular;
  }

  &_semi-b {
    @include font-medium;
  }

  &_underline {
    text-decoration: underline;
  }

  &_upper {
    text-transform: uppercase;
  }

  &_right {
    text-align: right;
  }
}

/* float */
.float {
  &_l {
    float: left;
  }

  &_r {
    float: right;
  }
}

/* display */
.display {
  &_ib {
    display: inline-block;
  }

  &_b {
    display: block;
  }
}

/* width */
.w {
  &_40 {
    width: 40%;
  }

  &_50 {
    width: 50%;
  }
}

/* height */
.h {
  &_50 {
    height: 50px;
  }

  &_34 {
    height: 34px;
  }
}

/* margin */
.m {
  &-l {
    &_35 {
      margin-left: 35px;
    }

    &_7 {
      margin-left: 7px;
    }

    &_9 {
      margin-left: 9px;
    }
  }

  &-r {
    &_5 {
      margin-right: 5px;
    }

    &_6 {
      margin-right: 6px;
    }

    &_27 {
      margin-right: 27px;
    }
  }

  &-t {
    &_16 {
      margin-top: 16px;
    }

    &_20 {
      margin-top: 20px;
    }

    &_30 {
      margin-top: 30px;
    }
  }

  &-b {
    &_40 {
      margin-bottom: 40px;
    }

    &_33 {
      margin-bottom: 33px;
    }
  }
}

/* padding */
.p {
  &-l {
    &_app {
      padding-left: setting($app, side-padding);
    }
  }

  &-r {
    &_app {
      padding-right: setting($app, side-padding);
    }

    &_73 {
      padding-right: 73px;
    }

    &_56 {
      padding-right: 56px;
    }
  }
}

/* position */
.pos {
  &_r {
    position: relative;
  }
}

/* top */
.top {
  &_1 {
    top: 1px;
  }

  &_2 {
    top: 2px;
  }

  &_3 {
    top: 3px;
  }

  &_4 {
    top: 4px;
  }
}

.line {
  &_h {
    &_1 {
      line-height: 1;
    }
  }
}

/* opacity */
.op {
  &_0 {
    opacity: 0;
  }
}

/* z-index */
.z-i {
  &_999 {
    z-index: 999;
  }
}

/* Transform */
.transform_90 {
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
