$grey: (
  lightest-5: #e9eae7,
  lightest-4: #f3f4f0,
  lightest-3: #b2b3b0,
  lightest-2: #f5f5f5,
  lightest: #e6e6e6,
  lighter-6: #eee,
  lighter-5: #ddd,
  lighter-4: #666,
  lighter-3: #aaa,
  lighter-2: #898989,
  lighter: #e7e8e1,
  light-6: #7f7f7f,
  light-5: #ccc,
  light-4: #7d7e7e,
  light-3: #878787,
  light-2: #9b9b9b,
  light: #8a8a8a,
  default: #838383,
  dark: #282828,
  dark-2: #454545,
  dark-3: #444,
  dark-4: #c0c0c0,
  dark-5: #95989a,
  darker: #525252,
  darker-2: #2c2823,
  darkest: #2c2c2c,
  darkest-2: #333,
  darkest-3: #262626,
  darkest-4: #282828,
  darkest-5: #222,
  darkest-6: #111,
);

$purple: (
  lightest: #fbf8ff,
  lighter: #7023a7,
  light-3: #a35fe7,
  light-2: #670ac3,
  light: #7c29af,
  default: #6a3b8f,
  dark: #774eb4,
  darker: #2c2436
);

$green: (
  default: #1dc252
);

$red: (
  default: #f5505a,
);

$error: #E64A4A;

$pink: (
  lightest: #fafafa,
  lighter: #f6e9ff,
);

$black: #000;
$white: #fff;
