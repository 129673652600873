.Alert {
    h4 {
        margin-top: 6px;
    }
    
    &__top {
        margin-bottom: 36px;
    }

    &__items__header {
        margin-bottom: 28px;
    }

    &__items__row {
        position: relative;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 48px;
        }
    }

    &__items__column {
        float: left;
        margin-right: 20px;
        width: 200px;
        min-height: 35px;
    }

    &__recipients-wrapper {
        font-size: 12px;
        padding-top: 12px;
        padding-bottom: 108px;

        small {
            color: color($grey, lightest-3);
            font-size: 10px;
        }

        a {
            color: color($purple, light-2);
            @include font-regular;

            &:hover,
            &:focus {
                color: color($purple, light-3);
            }
        }
    }

    &__recipients {
        list-style: none;
        margin: 15px 0;
        padding: 0;
        max-height: 225px;
        overflow-y: auto;
    }

    &__recipient {
        position: relative;
        color: $white;
        font-size: 14px;
        line-height: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 10px 26px 11px 14px;
        margin-bottom: 10px;
        background-color: color($purple, light-2);

        .Alert__recipient__remove {
            position: absolute;
            color: $white;
            font-size: 20px;
            top: 6px;
            right: 14px;

            &:hover,
            &:focus {
                color: white;
            }
        }
    }

    &__items__column__range {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        margin-top: 4px;
    }

    &__range__left {
        margin-right: 10px;
    }

    &__range__middle {
        position: relative;
        display: flex;
    }

    &__range__right {
        margin-left: 10px;
    }

    &__range__number {
        position: absolute;
        font-size: 12px;
        @include font-bold;
        text-align: center;
        margin-top: -36px;
        width: 26px;
        top: 50%;
        left: 0;
    }

    &__new {
        padding-top: 10px;
        margin-bottom: 48px;
    }

    &__add {
        position: relative;
        color: color($purple, light-2);
        font-size: 16px;
        @include font-bold;
        line-height: 1;
        text-transform: uppercase;
        margin-bottom: 52px;

        &:after {
            content: '+';
            color: $white;
            font-size: 20px;
            @include font-light;
            line-height: 1;
            background: color($purple, light-2);
            display: block;
            width: 26px;
            height: 26px;
            text-align: center;
            border-radius: 50%;
            position: absolute;
            right: -44px;
            top: -3px;
        }

        &:hover,
        &:focus {
            color: color($purple, light-3);

            &:after {
                background: color($purple, light-3);
            }
        }

        &:focus {
            color: color($purple, light-2);

            &:after {
                background: color($purple, light-2);
            }
        }
    }

    &__remove,
    &__reset {
        float: right;
        position: relative;
        color: $white;
        font-size: 20px;
        @include font-light;
        line-height: 1;
        background: color($purple, light-2);
        margin-left: 10px;
        width: 26px;
        height: 26px;
        top: 4px;
        text-align: center;
        border-radius: 50%;

        &:hover,
        &:focus {
            color: $white;
            background: color($purple, light-3);
        }
    }

    &__reset {
        img {
            width: 14px;
            height: 14px;
        }
    }

    &__input {
        @include border-bottom($color: $black);
        @include font-regular;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        outline: 0;
        padding: 7px 0;
        color: $black;
        width: 100%;
    }

    input[type=range] {
        -webkit-appearance: none;
        margin: 0;
        width: 100%;
    }
    input[type=range]:focus {
        outline: none;
    }
    input[type=range]::-webkit-slider-runnable-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        animate: 0.2s;
        box-shadow: none;
        background: #cacbcc;
        border-radius: 0;
        border: none;
    }
    input[type=range]::-webkit-slider-thumb {
        box-shadow: none;
        border: none;
        height: 26px;
        width: 26px;
        border-radius: 50%;
        background: color($purple, darker);
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -12px;
    }
    input[type=range]:focus::-webkit-slider-runnable-track {
        background: #cacbcc;
    }
    input[type=range]::-moz-range-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        animate: 0.2s;
        box-shadow: none;
        background: #cacbcc;
        border-radius: 0;
        border: none;
    }
    input[type=range]::-moz-range-thumb {
        box-shadow: none;
        border: none;
        height: 26px;
        width: 26px;
        border-radius: 50%;
        background: color($purple, darker);
        cursor: pointer;
    }
    input[type=range]::-ms-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        animate: 0.2s;
        background: transparent;
        border-color: transparent;
        border-width: 16px 0;
        color: transparent;
    }
    input[type=range]::-ms-fill-lower {
        background: #cacbcc;
        border: none;
        border-radius: 0;
        box-shadow: none;
    }
    input[type=range]::-ms-fill-upper {
        background: #cacbcc;
        border: none;
        border-radius: 0;
        box-shadow: none;
    }
    input[type=range]::-ms-thumb {
        box-shadow: none;
        border: none;
        height: 26px;
        width: 26px;
        border-radius: 50%;
        background: color($purple, darker);
        cursor: pointer;
    }
    input[type=range]:focus::-ms-fill-lower {
        background: #cacbcc;
    }
    input[type=range]:focus::-ms-fill-upper {
        background: #cacbcc;
    }

    .tooltip {
        @include font-regular;
        text-transform: uppercase;

        &.in {
            opacity: 1;
        }

        .tooltip-inner {
            background: color($purple, darker);
            padding: 12px 36px;
            border-radius: 0;
        }

        .tooltip-arrow {
            border-top-color: color($purple, darker);
        }
    }
}
