html {
  box-sizing: border-box;
}

* {
  box-sizing: inherit;

  &::before,
  &::after {
    box-sizing: inherit;
  }
}

body {
  @include font-light;
  background: color($pink, lightest);
  font-size: setting($font-size);
}

h1,
h2,
h3,
h4 {
  color: color($grey, darker);
}

a {
  outline: 0;

  &:hover,
  &:focus {
    color: color($grey, dark);
    text-decoration: none;
  }
}
